.loader {
  background-image: url(../images/loading-background.svg);
  background-size: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  width: 50px;
  height: 50px;
  margin: 10px auto;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}

.centered {
  background-image: url(../images/loading-background.svg);
  background-size: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}
