@import '../../assets/scss/variables.scss';

.title {
  border: none !important;
  background: none !important;
  font-size: 30px !important;
  padding: 20px !important;
  text-align: center;
  width: 100%;
  font-family: Georgia,serif !important;
}

.createCenter {
  width: 90%;
  text-align: center;
}

.mainSegment {
  margin: 20px 0px 0px 0px;
}

.profileImageSaveButton {
  display: block !important;
  margin: 10px auto !important;
}

.blurb {
  color: $medium-grey;
  margin: 4px 0px 12px;
}

.userInfoSegment {
  border-bottom: 4px solid $light-grey;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.profileImagePreview {
  width: 100%;
}

.profileImagePreviewContainer {
  margin: 20px auto;
  max-width: 300px;
}
