@import "../../../assets/scss/variables";

.smallHeader {
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    text-align: center; 
}

.smallHeader a {
    &:not(:last-child)::after {
        content: "/";
        margin: 0  5px;
    }

    &:hover {
        opacity: 0.7;
    }
}

.link {
    color: $theme-dark !important;
    text-decoration: underline !important;
    font-weight: 300;
    font-size: 14px;
}

.enquiryForm {
    width: 100%;
}

.loginBox {
    padding: 40px;
    border-radius: 12px;
    width: 100%;
}

.bannerPage {
    padding: 10vw;
    background-color: $dark-grey;
}

.largeHeader {
    font-size: 60px;
}

.logo {
    width: 150px;
    display: block;
}

.flexSpread {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    gap: 1rem;
    margin: 0 50px;
}

.whiteText {
    color: $white;
}

.entityLogo {
    width: 200px;
    flex: 1;
    display: block;
    margin: 0 auto;
}

.headerText {
    font-size: 64px;
    font-weight: 600;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    color: $white;
}

.loading {
    width: 100vw;
    height: 100vh;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.spinner {
    border: 16px solid $lightest-grey; /* Light grey */
    border-top: 16px solid $blue; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.flexContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.partnerLogo { 
    width: 33%;
}

.h1, .h2,.h3, .h4, p, td, th {
    padding: 0;
    margin: 0;
    height: auto;
    color: $black;
}

.imageContainer {
    display: flex;
    max-width: 100%;
    align-: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.imageContainer img {
    width: 30%;
}

img.image {
    border-radius: 10px;
}

label.eventLabel {
    font-size: 16px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
}

.eventButton {
     padding: 10px 15px !important;
     min-height: 40px !important;
     background-color: $blue !important;
     color: $white !important;
     border-radius: 1000px !important;
     font-weight: 400 !important;
     width: 200px;
}

.separator {
    height: 2px;
    border-top: 1px solid $light-grey;
    margin: 30px 0;
}

.eventPill {
    height: 40px;
    border-radius: 1000px;
    border: 1px solid $white;
    background-color: transparent;
    color: $white;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 10px 15px
}

.hiddenSmall {
    display: none;
}

.error { 
    color: $white;
    font-size: 14px;
    background-color: $red;
    border-radius: 1000px;
    margin-top: 10px;
    padding: 10px 15px;
    min-height: 40px;
}

.success { 
    font-size: 14px;
    background-color: $green;
    border-radius: 1000px;
    margin: 10px 0;
    padding: 10px 15px;
    min-height: 40px;
}

.success p {
    color: $white;
}

.input input {
    padding: 10px !important;
    border-radius: 1000px !important;
    border: 1px solid $theme-dark !important;
    width: 100% !important;
    background-color: $white !important;
}

.taglineLarge {
    font-size: 24px;
    color: $white;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.tagline {
    font-size: 20px;
    color: $white;
    font-weight: 300;
}

.taglineSmall {
    font-size: 16px;
    color: $white;
    font-weight: 100;
}

.date {
    font-size: 24px;
    color: $white;
    font-weight: 300;
}

.contentContainer { 
    padding: 20px;
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

hr {
    border-top: 1px solid $black;
}

.fullHeader {
    width: 100%;
    background-position: center center;
    background-image: url('../../../assets/images/login-background.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 40px;
    text-align: center;
    border-radius: 0 0 20px 20px;
    background-color: rgba(0, 31, 95, 0.5);
    background-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

table {
  border-spacing: 2px;
  box-sizing: border-box;
  text-indent: 0;
  text-align: left;
  border-collapse: collapse;
}

thead {
	border-block-end: 2px solid $lighter-grey;
	background: $lightest-grey;
    border-radius: 14px;
    // height: 40px;
}

th,
td {
	border: 1px solid $lighter-grey;
	padding: 0.5rem 1rem;
}


.partnerLogo { 
    width: 75%;
}

ul, li {
    color: $black;
}



@media (min-width: 768px) {

    .partnerLogo { 
        width: 33%;
    }

    .fullImage {
        padding-top: 40px;
    }

    .perfectCenter {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .perfectCenter > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .registrationLargeContent {
        width: 60%;
        padding: 30px;
        background-color: rgba(255, 255, 255, 0.9);
    }
    .hiddenSmall {
        display: table-cell;
    }

    .flexSpread {
        flex-direction: row;
    }

    .imageContainer img {
        width: 18%;
    }
}
