@import '../../assets/scss/variables.scss';

.choice {
  background: $lightest-grey;
  margin-top: 20px;
  border-radius: 6px;
  padding: 20px;
}

.contact {
  border-top: 1px solid $light-grey;
  margin-top: 10px;
  padding: 10px 0px 10px
}

.contact > p {
  margin: 0;
}

.messageContainer {
    margin-top: 20px;
    flex-wrap: wrap;
    padding: 20px;
    display: flex;
    max-height: 300px;
    overflow-y: auto;
}

.messageGrey {
    background-color: $lightest-grey;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 12px;
    width: 80%;
}

.messageBlue {
    background-color: #327aa6;
    padding: 12px;
    color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 12px;
    width: 80%;
    margin-left: auto;
    align-self: flex-end;
}
