@import "../../../assets/scss/variables.scss";

.title {
  border: none !important;
  background: none !important;
  font-size: 30px !important;
  padding: 20px !important;
  text-align: center;
  width: 100%;
  font-family: Georgia, serif !important;
}

.createCenter {
  width: 90%;
  text-align: center;
}

.profileImageSaveButton {
  display: block !important;
  margin: 10px auto !important;
}

.topSegment {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.mainSegment {
  margin: 20px 0px 0px 0px;
}

.profileImagePreview {
  width: 100%;
}

.profileImagePreviewContainer {
  margin: 20px auto;
  max-width: 300px;
}

.clubImagePreview {
  display: block;
  border-radius: 6px;
  margin: 0 auto 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.clubImagePreviewContainer {
  position: relative;
  height: 200px;
}

.clubImagePreviewDeleteButton {
  position: absolute;
  top: 5px;
  right: 0px;
}
