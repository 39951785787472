@import '../../assets/scss/variables.scss';

.title {
  border: none !important;
  background: none !important;
  font-size: 30px !important;
  padding: 20px !important;
  text-align: center;
  width: 100%;
  font-family: Georgia,serif !important;
}

.createCenter {
  width: 90%;
  text-align: center;
}

.profileImageSaveButton {
  display: block !important;
  margin: 10px auto !important;
}

.topSegment {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.mainSegment {
  margin: 20px 0px 0px 0px;
}

.profileImagePreview {
  width: 100%;
}

.profileImagePreviewContainer {
  margin: 20px auto;
  max-width: 300px;
}

.descriptionEditor {
  height: 300px;
  margin-bottom: 40px;
}

.containerStyles {
    position: relative;
    display: inline-block;
    width: 100%;
}

.iconStyles {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    margin: auto;
    right: 2.5em;
    line-height: 1;
    z-index: 1;
}