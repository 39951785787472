.confirmationBox {
  top: 50% !important;
  transform: translateY(-50%);
  right: 0;
  left: 0 !important;
  margin: 0 auto !important;
  position: absolute;
  height: auto !important;
}

.confirmationBoxTextRed {
  margin-top: 0;
  color: #db2828;
}

.confirmationBoxTextGreen {
  margin-top: 0;
  color: #21ba45;
}

.confirmationBoxTextBlack {
    margin-top: 0;
    color: #000000;
  }
  