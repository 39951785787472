@import "./variables";
@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,500,500i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900,900i|Playfair+Display:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

// NORMALIZE
html,
body,
#root,
.App,
.full {
    width: 100%;
    height: 100%;
    background: $white;
    font-family: "Lato", sans-serif;
    color: $dark-grey !important;
}

.main {
    min-height: 100%;
}

.main .container {
    margin-top: 20px;
    padding-bottom: 20px;
}

.display-font {
    font-family: "Playfair Display", Georgia, serif !important;
    font-weight: 600 !important;
}

a {
    color: $dark-grey !important;
    font-weight: bold;
}

h1 {
    font-size: 3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lato", sans-serif !important;
    color: $theme;
}

.skinny {
    max-width: 80vw;
    margin: 0 auto;
}

.ui.button {
    border-radius: 8px;
}

.ui.theme.button {
    background: $theme;
}

.ui.theme.button:hover,
.ui.theme.button:active,
.ui.theme.button:focus {
    background: $theme-dark;
}

.ui.green.button {
    background: $green;
}

.ui.red.button {
    background: $red;
}

.ui.red.button:hover,
.ui.red.button:active,
.ui.red.button:focus {
    background: $red;
}

.ui.green.button:hover {
    background: $dark-green;
}

.ui.modal > .content {
    background: $white;
    border-radius: 12px;
}

.ui.modal {
    border-radius: 12px;
    position: relative;
    height: auto;
}

input,
.ui.input > input {
    font-family: "Lato", sans-serif;
}

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
    color: $dark-grey !important;
}

* {
    box-sizing: border-box;
}

.full-height {
    height: 100%;
}

.page-header {
    padding-bottom: 20px;
}

.DayPickerInput {
    position: relative;
}

.DayPicker-Day {
    border-radius: 6px !important;
}

// GRID
//

// .container {
// 	width: 100%;
//
// 		.grid {
// 			display: flex;
//
// 			.col {
// 				flex: 1;
// 			}
//
// 			.col-1 {
// 					width: 10%;
// 			}
//
// 			.col-2 {
// 					width: 12.5%;
// 			}
//
// 			.col-3 {
// 					width: 25%;
// 			}
//
// 			.col-4 {
// 					width: 33.333%;
// 			}
//
// 			.col-6 {
// 					width: 50%;
// 			}
//
// 			.col-8 {
// 					width: 75%;
// 			}
//
// 			.col-9 {
// 					width: 66.666%;
// 			}
//
// 			.col-10 {
// 					width: 87.5%;
// 			}
//
// 			.col-11 {
// 					width: 90%;
// 			}
// 		}
// }

.date-input {
    padding: 10px;
    box-shadow: none;
    background: $lighter-grey;
    color: $dark-grey !important;
    border: none;
    border-radius: 10px;
}

.ui.label {
    border-radius: 10px;
}

.ui.input > input[type="text"],
.ui.input > input[type="number"],
.ui.input > input[type="time"],
.ui.input > input[type="password"],
.ui.input > input[type="email"],
.ui.form textarea {
    box-shadow: none;
    background: $lighter-grey;
    color: $dark-grey !important;
    border: none;
    border-radius: 10px;
}

.ui.input > input[type="text"]:focus,
.ui.input > input[type="number"]:focus,
.ui.input > input[type="time"]:focus,
.ui.input > input[type="password"]:focus,
.ui.input > input[type="email"]:focus,
.ui.form textarea:focus {
    box-shadow: none;
    background: $light-grey;
    color: $dark-grey !important;
    border: none;
    border-radius: 10px;
}

.ui.form textarea {
    resize: none;
}

.ui.toggle.checkbox {
    line-height: 22px;
}

.ui.toggle.checkbox label::before {
    height: 2rem;
}

.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before {
    background-color: $theme !important;
}

.ui.toggle.checkbox label::after {
    width: 1rem;
    height: 1rem;
    top: 7px;
    background: #ffffff;
}

.ui.toggle.checkbox input:checked ~ .box::after,
.ui.toggle.checkbox input:checked ~ label::after {
    left: 1.9rem;
    box-shadow: none;
}

.ui.toggle.checkbox input ~ .box::after,
.ui.toggle.checkbox input ~ label::after {
    left: 0.5rem;
    box-shadow: none;
}

.has-error {
    background: $red;
    color: #ffffff;
    padding: 4px 8px;
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 6px;
}

label.with-input {
    line-height: 2;
    display: block;
}

.ui.button {
}

.container.with-header {
    padding-top: 50px;
}

.container.gutters {
    padding-left: 10%;
    padding-right: 10%;
}

.container.pad-top {
    padding-top: 80px;
}

// UTILITIES

.center-content {
    display: flex;
    align-items: center;
}

.hidden {
    display: none !important;
}

.perfect-center {
    position: relative;
    width: 100%;
    height: 100%;

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.center-content {
    text-align: center;
}

.center {
    margin: 0 auto !important;
    display: block;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.dark-background {
    background: $dark-grey;
}

.light-background {
    background: $white;
}

.text-white {
    color: #ffffff;
}

.light-grey {
    color: $light-grey !important;
}

.ui.selection.dropdown {
    box-shadow: none;
    background: $lighter-grey;
    color: $dark-grey !important;
    border: none;
    border-radius: 10px;
    transition: none;
    min-height: 36px;
    padding-right: 36px !important;
}

.ui.selection.dropdown .search.icon {
    padding: 10px;
}

.ui.multiple.dropdown > .label {
    padding: 7px;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.1);
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover {
    z-index: 3;
    border: none;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.1);
}

.ui.selection.active.dropdown:hover .menu {
    border: none;
    box-shadow: none;
}

.ui.selection.dropdown .menu,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown .menu:hover {
    box-shadow: none;
    min-width: 100%;
    margin: 0px;
    width: 100%;
    background: $lighter-grey;
    color: $dark-grey !important;
    border: none;
    border-radius: 0px 0px 10px 10px;
}

.ui.selection.dropdown .menu > .item {
    border: none;
}

form .ui.toggle.checkbox label {
    margin-top: 10px;
}

.ql-editor {
    padding: 0px;
}

.ql-editor.ql-blank::before {
    left: 0px;
}

.ql-container.ql-snow {
    border: none !important;
    background: #ffffff;
    border-radius: 0px 0px 6px 6px;
    background: $lighter-grey;
    padding: 20px;
}
.ql-toolbar.ql-snow {
    border: none !important;
    background: $lighter-grey;
    border-radius: 6px 6px 0px 0px;
}

.ql-editor.ql-blank::before {
    color: #dbd8d7 !important;
    padding: 0px 20px;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.profile-logo-xs {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.profile-logo-sm {
    height: 40px;
    width: 40px;
    padding: 2px;
    border-radius: 50%;
}

.profile-logo-lg {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    display: block;
    padding: 4px;
    border: 8px solid $theme;
    border-radius: 50%;
}

.DayPickerInput-OverlayWrapper {
    z-index: 3;
}
