@import '../../assets/scss/variables.scss';

.search input {
	margin-top: 20px !important;
	border-radius: 8px !important;
}

.pieceContent p, .pieceContent h1, .pieceContent h2 {
	margin: 0;
}

.title {
  margin: 8px 0px 4px;
}

.blurb {
  color: $medium-grey;
  margin: 4px 0px 12px;
}

.pieceDetails span {
	margin-right: 10px;
}

.profileImage {
  height: 30px;
  width: 30px;
	padding: 2px;
	margin-right: 10px;
	border: 2px solid $theme;
	vertical-align: middle;
  border-radius: 50%;
}
