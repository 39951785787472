.transactionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.transactionWrapper {
    flex: 1;

    > div {
        width: 100%;
    }
}

.transactionSpan {
    margin: 0 40px;
}

.transactionError {
    color: #db2828;
    margin-top: 20px;
}