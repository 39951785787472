@import '../../assets/scss/variables';

.loginBox {
	padding: 40px;
	border-radius: 12px;
	width: 100%;
}

.bannerPage {
	padding: 10vw;
	background-color: $dark-grey;
}

.largeHeader {
	font-size: 60px;
}

.logo {
	width: 150px;
	margin-bottom: 40px;
	margin: 0 auto 40px;
	display: block;
}

.entityLogo {
    width: 150px;
    margin-bottom: 40px;
    margin: 0 auto 40px;
    display: block;
    height: 150px;
}

.fullImage {
	width: 100%;
	height: 100%;
	background: url(../../assets/images/login-background.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.registrationLargeContent {
	width: 100%;
	padding: 30px;
	background-color: rgba(255, 255, 255, 0.9);
	margin: 0 auto;
}


.perfectCenter {
	position: relative;
	top: 0;
	left: 0;
	transform: none;
}

.perfectCenter > div {
	position: relative;
}

@media (min-width: 768px) {
	.fullImage {
		padding-top: 40px;
	}


	.perfectCenter {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.perfectCenter > div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.registrationLargeContent {
		width: 60%;
		padding: 30px;
		background-color: rgba(255, 255, 255, 0.9);
	}
}
