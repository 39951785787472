@import '../scss/_variables.scss';

.menu {
	padding: 10px;
	background: $dark-grey;
	width: 200px;
	height: 100%;
	position: fixed;
	top: 50px;
	left: 0px;
	color: #FFFFFF;
	z-index: 1000;
}

.menu ul {
	list-style: none;
	margin: 0px;
	padding: 10px;
}

.menu ul li {
	padding: 10px;
}

.menu ul li i {
	margin-right: 8px;
}

.menu ul li ul li {
	padding: 10px 10px 10px 30px;
}
