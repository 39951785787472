@import '../scss/variables.scss';

.header {
	min-width: 250px;
	min-height: 100%;
	color: $theme;
	z-index: 1000;
	position: relative;
	background: $lightest-grey;
}

.content {
	position: fixed;
	width: 250px;
	overflow-y: auto;
	height: 100%;
}

.header a {
	font-size: 16px;
	line-height: 2;
	color: $dark-grey !important;
}

.brandName {
	margin-top: 10px !important;
	color: $theme;
	letter-spacing: 3px;
	font-size: 16px;
}

.list{
	border-top: 4px solid $light-grey;
	margin-top: 10px;
}

.list h3 {
	margin-top: 20px !important;
}

.header ul {
	padding: 0px;
	list-style-type: none;
}

.header ul ul {
	padding-left: 20px;
	list-style-type: none;
}

.logo {
	width: 60%;
	display: block;
	margin: 0 auto;
}

.search {
	margin-top: 10px;
}

.menu_icon {
	color: $dark-grey !important;
	line-height: 0.3 !important;
	margin-left: 10px !important;
}

.user_image {
	background-color: #FFFFFF;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin: 10px 30px 0px 30px;
}

.profileImage {
  height: 30px;
  width: 30px;
	padding: 2px;
	margin-left: 5px;
	border: 2px solid $theme;
	vertical-align: middle;
  border-radius: 50%;
}
