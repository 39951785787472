@import '../scss/_variables.scss';

.notification {
	position: fixed;
	bottom: 20px;
	right: 20px;
	display: inline-block;
	width: auto;
	max-width: 100%;
	font-weight: bold;
	border-radius: 10px;
	color: $white;
	z-index: 3000;
}

.dismiss {
	cursor: pointer;
	border-radius: 10px 0px 0px 10px;
	padding: 10px 14px;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.1);
	font-weight: bold;
}

.dismiss > i {
	margin: 0px !important;
}

.content {
	padding: 10px 20px;
	display: inline-block;
}

.notification > .content > p {
	margin: 0px;
}

.info {
	background-color: $theme;
}

.error {
	background-color: $red;
}

.success {
	background-color: $green;
}
