@import '../../assets/scss/variables.scss';


.imagePreview {
  width: 100%;
}

.imagePreviewContainer {
  margin: 20px auto;
  max-width: 300px;
}
