@import '../../assets/scss/variables.scss';

.title {
  border: none !important;
  background: none !important;
  font-size: 30px !important;
  padding: 20px !important;
  text-align: center;
  width: 100%;
  font-family: Georgia,serif !important;
}

.createCenter {
  width: 90%;
  text-align: center;
}

.profileImageSaveButton {
  display: block !important;
  margin: 10px auto !important;
}

.topSegment {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.mainSegment {
  margin: 20px 0px 0px 0px;
}

.eventImagePreview {
  display: block;
  border-radius: 6px;
  margin: 0 auto 10px;
  object-fit: cover;
  height: 300px;
}


.partnerImagePreview {
  display: block;
  border-radius: 6px;
  margin: 0 auto 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.partnerImagePreviewContainer {
  position: relative;
  height: 200px;
}

.partnerImagePreviewDeleteButton {
  position: absolute;
  top: 5px;
  right: 0px;
}

.choice {
    background: $lightest-grey;
    margin-top: 20px;
    border-radius: 6px;
    padding: 20px;
}

.errorModal {
    top: 50% !important;
    transform: translateY(-50%);
    right: 0;
    left: 0 !important;
    margin: 0 auto !important;
    position: absolute;
    height: auto !important;
}

.modalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px auto;
}

.modalHeading {
    font-size: 18px;
}

.modalNumber {
    font-size: 30px;
}

.modalFooterText {
    font-size: 18px;
}

.modalFooterErrorText {
    font-size: 18px;
    margin: 20px 0;
}

.bold {
    font-weight: bold;
}

.bookingError {
    color: #db2828;
    margin: 10px;
    font-weight: bold;
}

.playerError {
    color: #db2828;
    font-weight: bold;
}

.generalText {
    font-size: 18px;
}

.generalHeading {
    font-size: 20px;
}

.smallText {
    font-size: 14px;
    font-weight: normal;

    &:hover {
        text-decoration: underline;
    }
}

.playersText {
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
    vertical-align: middle;
}

.guestList {
    margin: 10px 0 0 0;
}

.containerStyles {
    position: relative;
    display: inline-block;
    width: 100%;
}

.iconStyles {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    margin: auto;
    right: 2.5em;
    line-height: 1;
    z-index: 1;
}