$black: #000000;
$dark-grey: #535e62;
$medium-grey: #999999;
$light-grey: #E3E3E3;
$lighter-grey: #E9E9E9;
$lightest-grey: #f4f6f8;
$white: #ffffff;
$theme: #1f2335;
$theme-dark: #1f2335;
$green: #6bd0af;
$red: #d06868;
$dark-green: #54a88c;
$blue: #001F5F